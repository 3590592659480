<template>
	<BaseEditControls
		:block-id="$parent.blockId"
		:block-ref="$parent.blockRef"
		button-title="Edit feed"
	>
		<ZyroPopupCard
			type="editor"
			title="Instagram feed"
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditInstagramFeedTabGeneral from './EditInstagramFeedTabGeneral.vue';
import EditInstagramFeedTabLayout from './EditInstagramFeedTabLayout.vue';

const tabs = [
	{
		title: i18n.t('common.general'),
		component: 'EditInstagramFeedTabGeneral',
	},
	{
		title: i18n.t('common.layout'),
		component: 'EditInstagramFeedTabLayout',
	},
];

export default {
	components: {
		BaseEditControls,
		EditInstagramFeedTabGeneral,
		EditInstagramFeedTabLayout,
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	tabs,
};
</script>
