<template>
	<div>
		<div class="edit-instagram-feed__account">
			<div
				v-if="instagramUsername"
				class="edit-instagram-feed__connected"
			>
				<span class="edit-instagram-feed__status z-body-small">
					{{ $t('builder.editInstagram.tabGeneral.instagramConnected') }}
				</span>
				<a
					class="edit-instagram-feed__link z-body-small"
					:href="`https://instagram.com/${instagramUsername}/`"
					target="_blank"
				>
					@{{ instagramUsername }}
				</a>
				<ZyroButton
					theme="outline"
					@click="disconnectInstagram"
				>
					{{ $t('common.disconnect') }}
				</ZyroButton>
			</div>
			<div
				v-else
				class="edit-instagram-feed__connect"
			>
				<ZyroSvg
					name="instagram"
					class="edit-instagram-feed__icon"
				/>
				<div class="edit-instagram-feed__description z-body-small">
					{{ $t('builder.editInstagram.tabGeneral.connectYourInstagramInfo') }}
				</div>
				<ZyroButton
					theme="primary"
					@click="connectInstagram"
				>
					{{ $t('builder.editInstagram.tabGeneral.connectInstagram') }}
				</ZyroButton>
			</div>
		</div>
		<ZyroFieldRange
			:label="$t('common.items')"
			units=""
			:value="itemCount"
			min="1"
			max="24"
			@input="itemCount = $event.target.value"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import {
	openAuthorizationWindow,
	deleteToken,
} from '@/api/InstagramApi';

export default {
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		instagramUsername() {
			return this.currentElement.settings.username;
		},
		itemCount: {
			get() {
				return this.currentElement.settings['item-count'];
			},
			set(newValue) {
				this.setElementData({ data: { settings: { 'item-count': Number.parseInt(newValue, 10) } } });
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = this.currentElement;
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'setElementData',
			'pushElementDataToHistory',
		]),
		connectInstagram() {
			openAuthorizationWindow(JSON.stringify({ elementId: this.currentElementId }));
		},
		disconnectInstagram() {
			deleteToken(this.id);
			this.setElementData({ data: { settings: { username: null } } });
		},
	},
};
</script>

<style lang="scss" scoped>
.edit-instagram-feed {
	&__account {
		padding: 32px 0 40px;
		border-bottom: 0.5px solid $grey-300;
	}

	&__connected,
	&__connect {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__status {
		margin-bottom: 8px;
	}

	&__link {
		margin-bottom: 24px;
		text-decoration: none;
	}

	&__icon,
	&__description {
		margin-bottom: 24px;
	}

	&__description,
	&__status,
	&__link {
		text-align: center;
	}
}
</style>
