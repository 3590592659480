<template>
	<div>
		<ZyroFieldRange
			:label="$t('builder.editCommon.itemsPerRow')"
			class="edit-instagram-feed__style-range"
			units=""
			:value="itemsPerRow"
			min="1"
			max="16"
			@input="itemsPerRow = $event.target.value"
		/>
		<ZyroFieldRange
			:label="$t('builder.editCommon.gapBetweenPhotos')"
			class="edit-instagram-feed__style-range"
			:value="itemGap"
			min="0"
			max="72"
			step="8"
			@input="itemGap = $event.target.value"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

export default {
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapState('gui', ['isMobileView']),
		...mapGetters(['currentElement']),
		itemsPerRow: {
			get() {
				const key = this.isMobileView ? 'm-items-per-row' : 'items-per-row';

				return this.currentElement.settings.styles[key];
			},
			set(newValue) {
				const key = this.isMobileView ? 'm-items-per-row' : 'items-per-row';

				this.setElementData({ data: { settings: { styles: { [key]: Number.parseInt(newValue, 10) } } } });
			},
		},
		itemGap: {
			get() {
				const key = this.isMobileView ? 'm-item-gap' : 'item-gap';

				return Number.parseInt(this.currentElement.settings.styles[key], 10);
			},
			set(newValue) {
				const key = this.isMobileView ? 'm-item-gap' : 'item-gap';

				this.setElementData({ data: { settings: { styles: { [key]: `${newValue}px` } } } });
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'setElementData',
			'pushElementDataToHistory',
		]),
	},
};
</script>

<style lang="scss" scoped>
.edit-instagram-feed {
	&__style-range:not(:last-of-type) {
		padding-bottom: 24px;
		border-bottom: 0.5px solid $grey-300;
	}
}
</style>
